import {userActions} from '../config/actions';

const defaultState = {
    isLoading: null,
    data: [],
    error: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case userActions.USER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
                data: state.data || false,
                error: null
            };
        case userActions.USER_ERROR:
            return {
                ...state,
                isLoading: false,
                data: state.data || [],
                error: action.error
            };
        case userActions.USER_NEW:
            return {
                ...state,
                isLoading: false,
                data: [action.data, ...state.data],
                error: null
            };
        case userActions.USER:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null
            };
        case userActions.USER_RESET:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: null
            };
        case userActions.USER_GET:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: null
            };
        default:
            return state;
    }
};
