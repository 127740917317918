import { paymentActions } from '../config/actions';
/* webpay */
const defaultState = {
  isLoading: null,
  data: {},
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case paymentActions.PAYMENT_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        data: state.data || false,
        error: null
      };
    case paymentActions.PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        data: state.data || {},
        error: action.error
      };
    case paymentActions.PAYMENT:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case paymentActions.PAYMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: null
      };

    default:
      return state;
  }
};
