import { iotActions } from '../config/actions';

const defaultState = {
  isLoading: null,
  status: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case iotActions.POLICY_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        status: state.status || false,
        error: null
      };
    case iotActions.POLICY_ERROR:
      return {
        ...state,
        isLoading: false,
        status: false,
        error: action.error
      };
    case iotActions.POLICY_ATTACHED:
      return {
        ...state,
        isLoading: false,
        status: true,
        error: null
      };
    case iotActions.POLICY_RESET:
      return {
        ...state,
        isLoading: false,
        status: false,
        error: null
      };
    default:
      return state;
  }
};
