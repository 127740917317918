import { combineReducers } from 'redux';
import policy from './policy';
import notifications from './notification-reducer';

import users from './user-reducer';
import pymes from './pyme-reducer';
import payment from './webpay-reducer';

export default combineReducers({
  policy,
  notifications,
  users,
  pymes,
  payment
});
