/* eslint import/prefer-default-export: 0 */
export const iotActions = {
  POLICY_IS_LOADING: 'POLICY_IS_LOADING',
  POLICY_ERROR: 'POLICY_ERROR',
  POLICY_ATTACHED: 'POLICY_ATTACHED',
  POLICY_RESET: 'POLICY_RESET',
  IOT_CLIENT_IS_LOADING: 'IOT_CLIENT_IS_LOADING',
  IOT_CLIENT_ERROR: 'IOT_CLIENT_ERROR',
  IOT_CLIENT: 'IOT_CLIENT',
  IOT_CLIENT_RESET: 'IOT_CLIENT_RESET',
  IOT_CLIENT_CONNECTED: 'IOT_CLIENT_CONNECTED',
  IOT_CLIENT_MESSAGE_ATTACHED: 'IOT_CLIENT_MESSAGE_ATTACHED',
  IOT_CLIENT_SUBSCIBED: 'IOT_CLIENT_SUBSCIBED'
};

export const notificationsActions = {
  NOTIFICATIONS_IS_LOADING: 'NOTIFICATIONS_IS_LOADING',
  NOTIFICATIONS_ERROR: 'NOTIFICATIONS_ERROR',
  NOTIFICATIONS_RESET: 'NOTIFICATIONS_RESET',
  NOTIFICATIONS_NEW: 'NOTIFICATIONS_NEW',
  NOTIFICATIONS: 'NOTIFICATIONS'
};

export const pushActions = {
  PUSH_IS_LOADING: 'PUSH_IS_LOADING',
  PUSH_ERROR: 'PUSH_ERROR',
  PUSH_RESET: 'PUSH_RESET',
  PUSH_NEW: 'PUSH_NEW',
  PUSH_SUBSCRIPTION: 'PUSH_SUBSCRIPTION',
  PUSH_STATUS: 'PUSH_STATUS'
};

export const sourceActions = {
  SOURCES_IS_LOADING: 'SOURCES_IS_LOADING',
  SOURCES_ERROR: 'SOURCES_ERROR',
  SOURCES_RESET: 'SOURCES_RESET',
  SOURCES_NEW: 'SOURCES_NEW',
  SOURCES: 'SOURCES'
};

export const userActions = {
  USER_IS_LOADING: 'USER_IS_LOADING',
  USER_ERROR: 'USER_ERROR',
  USER_RESET: 'USER_RESET',
  USER_NEW: 'USER_NEW',
  USER: 'USER',
  USER_GET: 'USER_GET'
};

export const pymeActions = {
  PYME_IS_LOADING: 'PYME_IS_LOADING',
  PYME_ERROR: 'PYME_ERROR',
  PYME_RESET: 'PYME_RESET',
  PYME_NEW: 'PYME_NEW',
  PYME: 'PYME',
  PYME_GET: 'PYME_GET',
  PYME_EDIT: 'PYME_EDIT'
};

export const paymentActions = {
  PAYMENT_IS_LOADING: 'PAYMENT_IS_LOADING',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAYMENT_RESET: 'PAYMENT_RESET',
  PAYMENT: 'PAYMENT'
};
