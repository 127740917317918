import { pymeActions } from '../config/actions';
/* pyme */
const defaultState = {
  isLoading: null,
  data: {},
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case pymeActions.PYME_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        data: state.data || false,
        error: null
      };
    case pymeActions.PYME_ERROR:
      return {
        ...state,
        isLoading: false,
        data: state.data || {},
        error: action.error
      };
    case pymeActions.PYME_NEW:
      return {
        ...state,
        isLoading: false,
        data: action.data || {},
        error: null
      };
    case pymeActions.PYME:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case pymeActions.PYME_RESET:
      return {
        ...state,
        isLoading: false,
        data: {},
        error: null
      };
    case pymeActions.PYME_GET:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case pymeActions.PYME_EDIT:
      return {
        ...state,
        isLoading: false,
        data: [action.data, ...state.data],
        error: null
      };
    default:
      return state;
  }
};
