import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';

import Storage from '@aws-amplify/storage';
import * as Sentry from '@sentry/react';
import configureStore from './app/store';
import config from './config';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './app/context/auth-context';
import './index.css';

const trackingId = 'UA-182745082-1';

ReactGA.initialize(trackingId);

ReactGA.pageview(window.location.pathname + window.location.search);

const store = configureStore();

// Amplify.configure({
//   Auth: {
//     mandatorySignIn: true,
//     region: config.cognito.REGION,
//     userPoolId: config.cognito.USER_POOL_ID,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID
//   },
//   // Storage: {
//   //   region: config.s3.REGION,
//   //   bucket: config.s3.BUCKET,
//   //   identityPoolId: config.cognito.IDENTITY_POOL_ID
//   // },
//   API: {
//     endpoints: [
//       {
//         name: 'pyme',
//         endpoint: config.apiGateway.URL,
//         region: config.apiGateway.REGION
//       }
//     ]
//   }
// });

Storage.configure({
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});
Auth.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

API.configure({
  endpoints: [
    {
      name: 'pyme',
      endpoint: config.apiGateway.URL,
      region: config.apiGateway.REGION
    }
  ]
});
// Fix to prevent API Not Configured error when build

Sentry.init({
  dsn:
    'https://954cc924d13c43e3b57b80c635a58186@o474096.ingest.sentry.io/5509859'
});

ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
