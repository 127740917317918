export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'navidad-pyme-assets-dev'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://pagos.navidadpyme.cl'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_xIMbRSa24', // 'us-east-1_4ciClAt3I',
    APP_CLIENT_ID: '2n2ha4ebppgh0fihteah1or4fa', // '2dkhsrt28sna0hfepucsqn0vcn'
    IDENTITY_POOL_ID: 'us-east-1:44af3119-6946-4c04-9c3a-3f9dec6b690c'
  }
};
